import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PushpinOutlined, PushpinFilled, SearchOutlined } from "@ant-design/icons";
import { DataTable, Form, PrimaryButton } from "../../components";
import Fields from "./FormFields.json";
import { ResponderCell, TitleCell } from "./Menu";
import {
  commonActions, getTaskSourceTypes, taskActions, getTasks, getTasksTotalCount, getAuditAssessmentsList,
  getTaskSourcesFromType, isMiscLoaded, getActivityTypeList, getCustomTypeList, getIncidentTypeList,
  getContinuityTypeList, getComplianceTask, getTaskBlueprintTemplates
} from "../../store";

const TASKS_PER_PAGE = process.env.REACT_APP_TASK_PER_PAGE || 9;

const Columns = [
  { title: 'Task ID', dataIndex: 'task_id_pin', key: 'task_id_custom', width: '176px', className:'no-wrap' },
  { title: 'Priority', dataIndex: 'priority', key: 'priority', width: '140px', sort: true, render: 'color-code' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '140px', sort: true, render: 'color-code' },
  { title: 'Title', dataIndex: 'title', key: 'title', width: '55%', search: true, supportServerSearch: true, classNames: '', render: (text) => <TitleCell text={text} /> },
  { title: 'Due Date', dataIndex: 'due_date_str', key: 'due_date_str', width: '150px', sort: true },
  { title: 'Aging', dataIndex: 'aging', key: 'aging', width: '80px', sort: "agingNum", classNames: { cell: 'txt-right' } },
  { title: 'Department', dataIndex: 'department', key: 'department', sort: true, width: '150px', },
  { title: 'Responder', dataIndex: 'responder', key: 'responder', width: '35%', sort: "main_responder", render: ResponderCell },
  { title: 'Frequency', dataIndex: 'repetition_occurs', key: 'repetition_occurs', width: '120px', },
]
const getPage = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('page') || 'recent'
}
export const TaskTracker = () => {
  const page = getPage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _ref = React.useRef(null)
  const tasks = useSelector(getTasks);
  const types = getTaskSourceTypes()
  const complainceTypes = getComplianceTask()
  const isLoaded = useSelector(isMiscLoaded)
  const taskCount = useSelector(getTasksTotalCount);
  const audits = useSelector(getAuditAssessmentsList);
  const activities = useSelector(getActivityTypeList);
  const customTypes = useSelector(getCustomTypeList)
  const incidents = useSelector(getIncidentTypeList);
  const continuityTests = useSelector(getContinuityTypeList)
  const bpList = useSelector(getTaskBlueprintTemplates.bind(null, false))
  const [typeFilter, setTypeFilter] = React.useState({ type: '', search: '' })
  const sources = useSelector(getTaskSourcesFromType.bind(null, false, typeFilter.type && typeFilter.type.id))
  const _filter = React.useRef({
    pageSize: TASKS_PER_PAGE, searchText: '', search: {}, query: {}, pageNo: page,
    defaultPage: page === 'recent' ? 1 : (Number(page) + 1), removeCount: true, enableRecent: true,
  })
  React.useEffect(() => {
    dispatch(commonActions.fetchDepartments());
    dispatch(commonActions.fetchTaskPriorities());
    dispatch(commonActions.fetchTaskStatus());
    dispatch(taskActions.fetchRepeatitionList());
    dispatch(taskActions.fetchDashboard());
    dispatch(taskActions.fetchAuditAssessmentsList());
    dispatch(taskActions.fetchActivityTypeList());
    dispatch(taskActions.fetchCustomTypeList());
    dispatch(taskActions.fetchIncidentTypeList());
    dispatch(taskActions.fetchContinuityTypeList());
    dispatch(taskActions.fetchTaskBlueprintTemplates());
  }, [])
  React.useEffect(() => {
    if (
      isLoaded && Array.isArray(audits) && Array.isArray(activities) &&
      Array.isArray(customTypes) && Array.isArray(incidents) && Array.isArray(continuityTests)
    ) {
      getPageCount();
      loadTasksFromQuery()
    }
  }, [isLoaded, audits, activities, customTypes, incidents, continuityTests])

  const getPageCount = () => {
    if (_ref.current) {
      let height = _ref.current.clientHeight
      height -= (32 + 52) // remove table header and pagination
      let count = Math.floor(height / 44) - 1;
      if (count > TASKS_PER_PAGE) {
        _filter.current.pageSize = count
      }
    }
  }
  const loadTasksFromQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const incidentGuid = queryParams.get('incident');
    const continuityGuid = queryParams.get('continuity');
    const auditGuid = queryParams.get('audit');
    const activity = queryParams.get('activity');
    const custom = queryParams.get('custom');
    const bp = queryParams.get('blueprint');

    let queryFilter = {};
    if (incidentGuid) {
      queryFilter.type = types.find(({ queryKey }) => (queryKey === 'incident'));
      queryFilter.search = incidents.find(({ guid }) => (guid === incidentGuid));
    } else if (continuityGuid) {
      queryFilter.type = types.find(({ queryKey }) => (queryKey === 'continuity'));
      queryFilter.search = continuityTests.find(({ guid }) => (guid === continuityGuid));
    } else if (auditGuid) {
      queryFilter.type = types.find(({ queryKey }) => (queryKey === 'audit'));
      queryFilter.search = audits.find(({ guid }) => (guid === auditGuid));
    } else if (activity) {
      queryFilter.type = types.find(({ queryKey }) => (queryKey === 'activity'));
      queryFilter.search = activities.find(({ id }) => (id === activity));
    } else if (custom) {
      queryFilter.type = types.find(({ queryKey }) => (queryKey === 'custom'));
      queryFilter.search = customTypes.find(({ id }) => (id === custom));
    } else if (bp) {
      queryFilter.type = types.find(({ queryKey }) => (queryKey === 'blueprint'));
      queryFilter.search = bpList.find(({ guid }) => (guid === bp));
    }
    if (Object.keys(queryFilter).length > 0) {
      setTypeFilter((_) => ({ ..._, ...queryFilter }))
    }
    handleSearchFilter(null, queryFilter)
  }
  const updateQueryString = () => {
    const { pageNo, query, searchText } = _filter.current;
    const searchParams = new URLSearchParams("");
    Object.keys(query).forEach(key => {
      searchParams.set(key, query[key]);
    });
    if (searchText.length) {
      searchParams.set("search", searchText)
    }
    searchParams.set("page", String(pageNo));
    let path = `${window.location.pathname}?${searchParams.toString()}`;
    navigate(path)
  }

  const loadTasks = () => {
    updateQueryString();
    const { pageNo, search, searchText, pageSize, removeCount } = _filter.current;
    let payload = { searchText, pageNo: pageNo || 'recent', search, pageSize, removeCount };
    dispatch(taskActions.fetchAllTasks(payload));
  };

  const getFilterFields = () => {
    const fields = Fields.Filter.map((_) => ({ ..._ }));
    if (typeFilter.type) {
      fields[1].type = 'autocomplete'
      fields[1].label = 'Source'
    }
    return fields
  }
  const getOptions = (attribute) => {
    let options = []
    if (attribute === 'type') {
      options = [...types, ...complainceTypes]
    } else if (attribute === 'search') {
      options = sources
    }
    return options
  }
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    let filter = { ...typeFilter };
    filter[name] = value;

    // If the type is changed, reset the search field
    if (name === 'type') {
      filter.search = '';
    }

    setTypeFilter(filter);

    // Check if both fields are cleared
    if (!filter.type && !filter.search) {
      // Trigger re-fetch of all tasks with no filters
      _filter.current = {
        ..._filter.current,
        search: {},
        query: {},
        searchText: '',
        enableRecent: true,
        pageNo: "recent",
        removeCount: true,
        defaultPage: 1,
      };
      loadTasks();
    }
  };

  const handleSearchFilter = (e, queryFilter = {}) => {
    let pageNo;
    _filter.current.searchText = '';

    // Clear previous filters
    types.forEach(({ fetchKey, queryKey }) => {
      delete _filter.current.search[fetchKey];
      delete _filter.current.query[queryKey];
    });

    const taskFilter = { ...(typeFilter), ...queryFilter };

    // Check if both fields are cleared
    if (!taskFilter.type && !taskFilter.search) {
      // No filters applied, re-fetch all tasks
      _filter.current = {
        ..._filter.current,
        search: {},
        query: {},
        searchText: '',
        enableRecent: true,
        removeCount: true,
      };
      loadTasks();
      return;
    }

    // Apply filtering logic if fields are not empty
    if (taskFilter.search) {
      if (taskFilter.type) {
        let { id, guid } = taskFilter.search;
        let { fetchKey, queryKey } = taskFilter.type;
        _filter.current.search[fetchKey] = id;
        _filter.current.query[queryKey] = guid || id;
      } else {
        _filter.current.searchText = taskFilter.search;
      }
      _filter.current.enableRecent = false;
      _filter.current.defaultPage = 1
      pageNo = 1;
    } else {
      _filter.current.enableRecent = true;
      pageNo = "recent";
    }
    // console.log("_filter", _filter);

    _filter.current.pageNo = pageNo;
    _filter.current.removeCount = true;
    _filter.current.defaultPage = pageNo === "recent" ? 1 : (Number(pageNo) + 1);

    loadTasks();
  };

  const handlePageChange = (pageNo) => {
    _filter.current = { ..._filter.current, pageNo: pageNo, removeCount: false }
    loadTasks()
  }
  const handleSearch = (colDataIndex, searchText) => {
    const search = { ...(_filter.current.search || {}), [colDataIndex]: searchText }
    _filter.current = { ..._filter.current, search, removeCount: true, pageNo: 1 };
    loadTasks();
  }
  const handlePinTask = (taskId, pinStatus) => {
    const updatedVal = { pin_task: pinStatus };
    dispatch(taskActions.updateTask({
      taskId,
      updatedVal,
      popup: { message: pinStatus ? 'Task Pinned Successfully' : 'Task Unpinned' }
    }));
  };
  const modifyTasks = Array.isArray(tasks) ? tasks.map((task) => {
    let temp = { ...task }, Icon = temp['pin_task'] ? PushpinFilled : PushpinOutlined;
    temp['task_id_pin'] = (
      <div className="row w-100">
        <Link to={temp.guid} className="c1133CC" >{temp['task_id']}</Link>
        {
          (_filter.current.pageNo !== 'recent') &&
          <Icon
            style={{ color: '#1133CC', marginLeft: 12 }}
            onClick={() => handlePinTask(temp['id'], !Boolean(temp['pin_task']))} />
        }
      </div>
    )
    return temp
  }) : null;
  return (
    <div className='col w-100 h-100 o-hide tracker' ref={_ref}>
      <div className="row tracker-filter ">
        <Form
          className='row '
          Fields={getFilterFields()}
          getOptions={getOptions}
          formData={typeFilter}
          onChange={handleFilterChange}
        />
        <PrimaryButton
          className="search-btn"
          onClick={handleSearchFilter}
          icon={<SearchOutlined style={{ fontSize: 14, color: '#FFF' }} />}
        />
      </div>
      <DataTable
        noSelection
        emptyMessage="There are no Tasks right now!"
        Columns={Columns}
        rows={modifyTasks}
        totalCount={taskCount}
        onSearch={handleSearch}
        onPageChange={handlePageChange}
        paginationConfig={{
          enableRecent: _filter.current.enableRecent,
          defaultPage: _filter.current.defaultPage,
          pageSize: _filter.current.pageSize
        }}
      />
    </div>
  )
}
