import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form } from "../../components";
import Fields from "./FormFields.json";
import {
  getDepartments, getTaskProrityTypes, getActiveUsers, getTaskSourceTypes, getTaskStatusTypes, getDateRangeEnums,
  getCurrentUser, getTaskSourcesFromType, taskActions, isOrganizer, AppActions, getComplianceTask
} from "../../store";
import { WarningFilled } from "@ant-design/icons";

const Actions = [
  { type: 'cancel', label: 'Cancel', ClassName: ' f14 exo2 bold-600' },
  { type: 'primary', label: 'Export', ClassName: ' f14 exo2 bold-600' },
]
const DefaultTask = {
  "priority": "",
  "status": "",
  "department": "",
  "source": "",
  "activity_type": "",
  "range": "",
  "responder": ""
}

export const ExportTaskForm = (props) => {
  const { open, onClose } = props;
  const _ref = useRef(null)
  const dispatch = useDispatch();
  const departments = useSelector(getDepartments);
  const priorities = useSelector(getTaskProrityTypes);
  const statusList = useSelector(getTaskStatusTypes);
  const activeOrgUsers = useSelector(getActiveUsers);
  const user = useSelector(getCurrentUser)
  const dateRangeEnumns = useSelector(getDateRangeEnums)
  const [state, setState] = useState({ task: { ...DefaultTask } });
  const sources = useSelector(getTaskSourcesFromType.bind(null, false, state.task.source && state.task.source.id))
  React.useEffect(() => {
    dispatch(taskActions.fetchDateRangeEnums());
  }, [])
  React.useEffect(() => {
    if (open) {
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          handleExportTask();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    } else {
      setState({ task: { ...DefaultTask } })
    }
  }, [open]);
  const getActions = () => {
    return Actions.map((_, i) => {
      let action = { ..._ };
      action.onClick = (i === 0) ? onClose : handleExportTask;
      return action;
    });
  };
  const getFields = () => {
    return Fields.Export.map((_, index) => {
      let field = { ..._, key: index };
      return field;
    });
  };
  const getOptions = (attribute) => {
    let options = [];
    let typeOptions = getTaskSourceTypes();
    const complainceTypes = getComplianceTask()
    switch (attribute) {
      case 'department':
        options = departments.map((dept, index) => ({ ...dept, key: index }));
        break;
      case 'priority':
        options = priorities.map((priority, index) => ({ ...priority, key: index }));
        break;
      case 'status':
        options = statusList.map(
          (status_val, index) => ({
            ...status_val, key: index
          })
        );
        break;
      case 'range':
        options = dateRangeEnumns
        break;
      case 'responder':
        options = activeOrgUsers.map((user, index) => {
          const [name, title] = user._designation.split('-').map(part => part.trim()); // Split the name and title by hyphen and trim spaces
          let formattedTitle = title.replace(' - ', ', ');
          return {
            label: `${name}, ${formattedTitle}`,
            id: user.id,
            key: index
          };
        });
        break;
      case 'source':
        options = [...typeOptions, ...complainceTypes].map((item, index) => ({ ...item, key: index }));
        break;
      case 'activity_type':
        options = sources.map((item, index) => ({ ...item, key: index }))
        break;
      default:
        options = options.map((item, index) => ({ ...item, key: index }));
        break;
    }
    return options;
  }
  const handleExportTask = () => {
    const payload = transformStateToPayload(state);
    if (!isOrganizer(user) && Object.keys(payload.task).length === 0) {
      dispatch(AppActions.setAlert({ success: false, message: 'Export All Tasks not allowed. Please contact Organiser.' }))
      return;
    }
    dispatch(taskActions.exportTasks(payload));
    onClose && onClose()
  }
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    const task = { ...state.task }
    task[name] = value;
    if (name === 'source') {
      task.activity_type = ''
    }
    setState((_) => ({ ..._, task }));
  };
  return (
    <>
      <Modal
        width={750}
        open={open}
        className="export-task-form"
        actions={getActions()}
        onCancel={onClose}
        title={<span className="exo2 f19 c238787">Export Tasks</span>}>
        {
          open &&
          <>
            <div className=" d-flex export-dialog-helptext">
              <WarningFilled className='cEF8E3F warning' />
              <div className="f14 ">
                If nothing is selected then ALL Tasks will be exported!<br />
                Only those Tasks will be exported for which you are a Responder.<br />
                To export all Tasks for {user?.ent_org?.brandname} contact an Organiser.
              </div>
            </div>
            <Form
              showColon
              ref={_ref}
              onSubmit={handleExportTask}
              className="form row fwarp h-btn"
              formData={{ ...state.task }}
              Fields={getFields()}
              getOptions={getOptions}
              onChange={handleValueChange}
            /></>

        }
      </Modal>
    </>
  )
}
const transformStateToPayload = (state) => {
  const { task } = state;
  const payload = {};
  Fields.Export.forEach(({ attribute, type, multiple }) => {
    let value = task[attribute];
    if (Boolean(multiple ? Array.isArray(value) && value.length > 0 : value)) {
      if (attribute === 'source' || attribute === 'activity_type') {
        if (task.source && task.activity_type) {
          payload[task.source.fetchKey || 'activity_type'] = task.activity_type.id;
        }
      } else {
        if (type === 'select') {
          if (multiple) {
            payload[attribute] = value.map((_) => _.id);
          } else {
            payload[attribute] = value.id;
          }
        } else if (type === 'autocomplete') {
          payload[attribute] = task[attribute].id
        }
      }
    }
  })
  return Object.keys(payload).length ? { task: payload } : {};
};